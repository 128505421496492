import React, { useState } from 'react';

import { Button, FlexBox } from '@eltoro-ui/components';
import CustomIcons from 'assets/icons';

import CampaignExamplesModal from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal';

import './CampaignExamplesWidget.scss';

interface CampaignExamplesWidgetProps {
  redirectToLens?: boolean;
}

export default function CampaignExamplesWidget({
  redirectToLens = true,
}: CampaignExamplesWidgetProps) {
  const [exampleModalOpen, setExampleModalOpen] = useState(false);

  const onSeeCampaignExamples = () => setExampleModalOpen(true);
  const onCancel = () => setExampleModalOpen(false);

  return (
    <FlexBox
      alignItems="center"
      justifyContent="space-between"
      UNSAFE_className="campaign-examples-widget"
      gap="2.5rem"
    >
      <FlexBox alignItems="center" gap="12px">
        <CustomIcons name="gift" fontSize={32} />
        <span className="campaign-examples-widget-description">
          View effective campaign examples tailored to each campaign type, aligned with the goal you
          want to achieve.
        </span>
      </FlexBox>
      <Button
        size="l"
        kind="text"
        onClick={onSeeCampaignExamples}
        UNSAFE_className="refresh-contacts"
      >
        See Campaign Examples
      </Button>
      {exampleModalOpen && (
        <CampaignExamplesModal redirectToLens={redirectToLens} onCancel={onCancel} />
      )}
    </FlexBox>
  );
}
