import React from 'react';

import { GridBox, Modal, Text } from '@eltoro-ui/components';

import creativePlaceholders from 'Pages/CampaignCreation/components/CampaignCreatives/_components/UploadBannerModal/CreativePlaceholders.json';

import cross from 'assets/Images/cross.png';

interface BannerSizesModalProps {
  onClickOutside: VoidFunction;
}

export default function BannerSizesModal({ onClickOutside }: BannerSizesModalProps) {
  return (
    <Modal>
      <div className="UploadBannerModal">
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <img
            src={cross}
            onClick={onClickOutside}
            alt="cross"
            className="PersonalInfoForm__cross"
            role="presentation"
          />
        </div>
        <GridBox gap="0.5rem">
          <Text
            tag="div"
            on="white"
            size="xxl"
            UNSAFE_style={{ marginBottom: '20px' }}
            UNSAFE_className="UploadMailerModal__step-subheading"
          >
            Acceptable Banner Sizes
          </Text>
          <div className="UploadBannerModal__hl" />
          <GridBox UNSAFE_className="UploadMailerModal__grid_box">
            {creativePlaceholders.map(creative => (
              <GridBox
                key={creative.id}
                gridTemplateColumns="min-content auto"
                alignItems="center"
                gap="1rem"
                UNSAFE_className="gridSizing"
              >
                <div
                  className="UploadBannerModal__creative-placeholder"
                  style={{ height: creative.height, width: creative.width }}
                />
                <Text on="white">
                  <b>{creative.actualDimensions}</b>
                  {creative.text}
                  {!!creative.textSecondary && <br />}
                  {!!creative.textSecondary && creative.textSecondary}
                </Text>
              </GridBox>
            ))}
          </GridBox>
        </GridBox>
      </div>
    </Modal>
  );
}
