import moment, { Moment } from 'moment';

import { PageSizes, PDFDocument } from 'pdf-lib';

import { TCommonSubscription, TCommonUser, TCreative, SignedTOS, PostMediaType } from 'types';
import visacard from 'assets/Images/visacard.png';
import mastercard from 'assets/Images/mastercard.png';
import discover from 'assets/Images/discover.png';
import americanexpress from 'assets/Images/americanexpress.png';
import Jcb from 'assets/Images/Jcb.png';
import dinearclub from 'assets/Images/dinearclub.png';
import momentTimezone from 'moment-timezone';
import { ET_TIMEZONE } from './constants';

export const subscription_name = (subscription: TCommonSubscription) => {
  return subscription?.name === 'beewo-subscription-basic'
    ? 'Basic Subscription'
    : 'Premium Subscription';
};

export const isEllipsisActive = (element: HTMLElement): boolean => {
  if (element) {
    const { offsetWidth, scrollWidth } = element;
    return !(offsetWidth < scrollWidth);
  }
  return false;
};

export function formatBytes(bytes: number | null) {
  if (!bytes) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Math.ceil(bytes / k ** i)} ${sizes[i]}`;
}

export function downloadCreative(creative: TCreative) {
  if (creative.presigned_url) {
    const link = document.createElement('a');
    link.href = creative.presigned_url;
    link.target = '_blank';
    link.setAttribute('download', `${creative.name}.${creative.file_type}`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function formatVideoDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}

export function compactNumberConverter(
  number: number,
  type: 'currency' | 'number' | 'percent',
  maximumFractionDigits: number = 3
): string {
  const currencyOptions = {
    style: 'currency',
    currency: 'USD',
    notation: number >= 1000 ? 'compact' : 'standard',
  };

  const percentOptions = {
    style: 'percent',
    maximumFractionDigits,
    minimumFractionDigits: 0,
  };

  const defaultOptions = {
    notation: 'compact',
  };

  const options =
    type === 'currency' ? currencyOptions : type === 'percent' ? percentOptions : defaultOptions;

  const formatter = new Intl.NumberFormat('en-US', options);

  const formattedNumber = type === 'percent' ? number / 100 : number;

  return formatter.format(formattedNumber);
}

export function isPrimitive(val: unknown): boolean {
  if (val === null) {
    return true;
  }

  return !(typeof val === 'object' || typeof val === 'function');
}

async function generateSignedTOS(
  user: TCommonUser,
  signedDate: Moment,
  consentText: string,
  consentSignatureImage: ArrayBuffer,
  tosMasterFile: ArrayBuffer
): Promise<Uint8Array> {
  const newPDF = await PDFDocument.load(tosMasterFile);
  const page = newPDF.addPage(PageSizes.A4);
  const pngImage = await newPDF.embedPng(consentSignatureImage);

  const pngDims = pngImage.scale(0.5);
  page.drawImage(pngImage, {
    x: page.getWidth() / 2 - pngDims.width / 2,
    y: page.getHeight() / 2 - pngDims.height / 2 - 75,
    width: pngDims.width,
    height: pngDims.height,
  });
  const fontSize = 15;
  page.drawText(consentText, {
    y: page.getHeight() - 75,
    x: 50,
    maxWidth: page.getWidth() - 150,
    size: fontSize,
  });

  newPDF.setTitle(
    `${user.email}-${user.first_name}-${user.last_name}-${signedDate.format(
      'MM/DD/YYYY, hh:mm:ss A'
    )}`
  );

  // eslint-disable-next-line no-return-await
  return await newPDF.save();
}

export async function createSignedTOS(
  signatureURL: string,
  tosMasterFileURL: string,
  user: TCommonUser
): Promise<SignedTOS> {
  const [signatureArrayBuffer, tosMasterFileArrayBuffer] = await Promise.all([
    fetch(signatureURL).then(response => response.arrayBuffer()),
    fetch(tosMasterFileURL).then(response => response.arrayBuffer()),
  ]);

  const signedDate = moment();

  const consentText = [
    `Signed by: ${user.first_name} ${user.last_name}`,
    `MLS Registered Name: ${user.mls_registered_name}`,
    `MLS Agent ID: ${user.mls_id}`,
    `Primary ZIP Code: ${user.primary_zip_code}`,
    `Brokerage: ${user.brokerage}`,
    `Phone number: ${user.phone_number}`,
    `State: ${user.state}`,
    `City: ${user.city}`,
    `Street: ${user.street}`,
    `ZIP Code: ${user.billing_zip_code}`,
    `Signed at: ${signedDate.format('MM/DD/YYYY, hh:mm:ss A')}`,
  ].join('\n\t');

  const signedTOSPDFBytes = await generateSignedTOS(
    user,
    signedDate,
    consentText,
    signatureArrayBuffer,
    tosMasterFileArrayBuffer
  );

  const blob = new Blob([signedTOSPDFBytes], {
    type: 'application/pdf',
  });

  const file = new File(
    [blob],
    `${user.email}-${user.first_name}-${user.last_name}-${signedDate.format(
      'MM/DD/YYYY, hh:mm:ss A'
    )}.pdf`,
    {
      type: 'application/pdf',
    }
  );

  return { blob, file };
}

export const isCountOver99 = (count: number): number | string => {
  if (count >= 100) return '99+';

  return count;
};

export const downloadPostCreative = async (media: PostMediaType): Promise<void> => {
  if (media.url) {
    try {
      const response = await fetch(media.url);
      if (!response.ok) {
        throw new Error('Failed to fetch the file.');
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', media.name || 'file');
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Revoke the object URL to free up memory
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  } else {
    console.error('Media URL is missing.');
  }
};

export const brand = (item: string) => {
  if (item === 'visa') {
    return visacard;
  }
  if (item === 'mastercard') {
    return mastercard;
  }
  if (item === 'discover') {
    return discover;
  }
  if (item === 'american_express') {
    return americanexpress;
  }
  if (item === 'jcb') {
    return Jcb;
  }
  if (item === 'diners_club') {
    return dinearclub;
  }
  return '';
};

export const isValidWorkingDay = (day: Date): boolean => {
  const timezoneCalendarDay = momentTimezone.tz(day, ET_TIMEZONE);
  const userTimezone = momentTimezone.tz.guess();
  const nowUserDate = momentTimezone.tz(new Date(), userTimezone);
  const nowET = momentTimezone.tz(new Date(), ET_TIMEZONE);
  const timezoneDay = momentTimezone.tz(nowUserDate, ET_TIMEZONE);

  const isToday = timezoneDay.isSame(timezoneCalendarDay, 'day');
  const isSaturday = nowET.isoWeekday() === 6; // Saturday
  const isSunday = nowET.isoWeekday() === 7; // Sunday
  const isTomorrow = timezoneCalendarDay.isSame(nowET.clone().add(1, 'day'), 'day');

  const isSameDayAsToday = timezoneCalendarDay.isSame(nowET, 'day');
  const isSameDayAsSaturday = timezoneCalendarDay.isSame(nowET.clone().add(1, 'day'), 'day');
  const isSameDayAsSunday = timezoneCalendarDay.isSame(nowET.clone().add(2, 'days'), 'day');

  const isFridayAfterHours =
    nowET.isoWeekday() === 5 &&
    nowET.hour() >= 16 && // Today is Friday after 16:00
    (isSameDayAsToday || isSameDayAsSaturday || isSameDayAsSunday); // Disable today, Saturday, and Sunday

  const isNotWorkingHour = isToday && timezoneDay.hour() >= 16;

  return (
    (isSaturday && (isToday || isTomorrow)) || // Disable Saturday and Sunday when today is Saturday
    (isSunday && isToday) || // Disable only Sunday when today is Sunday
    isFridayAfterHours || // Disable Friday after 16:00 ET
    isNotWorkingHour // Disable during non-working hours
  );
};

export const adjustDates = (
  startDate: Date,
  suggestedDates?: number
): { startDate: Date; endDate: Date } => {
  const userTimezone = momentTimezone.tz.guess();
  const nowUserStartDate = momentTimezone.tz(startDate, userTimezone);

  const initialStart = momentTimezone.tz(startDate, ET_TIMEZONE);

  const isSaturday = initialStart.isoWeekday() === 6;
  const isSunday = initialStart.isoWeekday() === 7;
  const workingHour = initialStart.hour();

  if (isSaturday) {
    nowUserStartDate.add(2, 'days');
  } else if (isSunday) {
    nowUserStartDate.add(1, 'day');
  } else if (workingHour >= 16) {
    if (initialStart.isoWeekday() === 5) {
      // Friday after working hours
      nowUserStartDate.add(3, 'days');
    } else {
      nowUserStartDate.add(1, 'day');
    }
  }
  const adjustedEnd = nowUserStartDate
    .clone()
    .add(suggestedDates ? suggestedDates - 1 : 14, 'days');

  return {
    startDate: nowUserStartDate.toDate(),
    endDate: adjustedEnd.toDate(),
  };
};
