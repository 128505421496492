import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

import { Button, FlexBox, MaxHeightContainer } from '@eltoro-ui/components';

import { MapMarker, SaveAudienceWizard } from 'Pages/ProspectActivity/components';
import ZipCodesList from 'Pages/ProspectActivity/components/ZipCodesList';
import { Map, MapPopup, MapToolTip } from 'Components';
import { LinearLoader } from 'Components/LinearLoader';
import { LensSidebar, LensSidebarHeader } from 'Pages/ProspectActivity/lenses/components';
import { useSelectedListingMarker } from 'Pages/ProspectActivity/lenses/components/LensSelectedMarker/LensSelectedMarker';

import { getIconBuzzColor } from 'Helpers';

import { getContactsZipCodes } from 'Requests/Request_Methods/contactsMethods';
import { usePermissionContext } from 'contexts/PermissionContext';
import type { ContactZipCode, TLocation } from 'types';

import './GrowYourSphere.scss';

export const GrowYourSphere = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [polygonsLoading, setPolygonsLoading] = useState<boolean>(false);
  const { userIsActive } = usePermissionContext();
  const [contactsZips, setContactsZips] = useState<ContactZipCode[]>([]);
  const [selectedContactsZips, setSelectedContactsZips] = useState<ContactZipCode[]>([]);
  const [polygonLocations, setPolygonLocations] = useState<TLocation[]>([]);

  const getZipCodes = async () => {
    try {
      setLoading(true);
      const res = await getContactsZipCodes();
      if (res.data) setContactsZips(res.data);
    } catch (e) {
      if ('detail' in e && typeof e.detail === 'string') toast.error(e.detail);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userIsActive) {
      getZipCodes();
    }
  }, []);

  return (
    <div className="GrowYourSphere">
      <LensSidebar UNSAFE_className="GrowYourSphere_sidebar" root_UNSAFE_className="root_content">
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Grow Your Sphere"
              subHeading="Analyze your contacts to expand your sphere and engage with those households observed at your contact’s homes."
            />
          }
          footer={
            !loading ? (
              contactsZips.length ? (
                <FlexBox flexDirection="column" gap="8px">
                  <SaveAudienceWizard
                    type="Grow Your Sphere"
                    onSaveAndClose={() => getZipCodes()}
                    selectedZips={selectedContactsZips.map(({ zip }) => zip)}
                    saveText="Save Second Degree Contacts"
                    totalProspects={0}
                    listing={selectedContactsZips}
                    disabled={polygonsLoading || !selectedContactsZips.length}
                  />
                </FlexBox>
              ) : (
                <Button
                  UNSAFE_className="MyListingsLens__FooterBtn"
                  kind="primary"
                  weight="bold"
                  size="l"
                  width="100%"
                  to="/my-sphere"
                >
                  Go to My Sphere
                </Button>
              )
            ) : null
          }
        >
          <div className="MyListingsLens__sidebar-content GrowYourSphere__sidebar-content">
            <ZipCodesList
              loading={loading}
              zips={contactsZips}
              selectedZips={selectedContactsZips}
              onSelectZip={zips => setSelectedContactsZips(zips)}
              setPolygonLocations={setPolygonLocations}
              polygonsLoading={polygonsLoading}
              setPolygonsLoading={setPolygonsLoading}
            />
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      <div className="grow-your-sphere-map-rendering-info">
        <FontAwesomeIcon icon={faExclamationCircle} color="#F6B432" style={{ fontSize: 21 }} />
        <span className="description">
          The map shows the top 5 zip codes for first-degree contacts.
        </span>
      </div>

      <div className="MyListingsLens__map GrowYourSphere__map">
        <div style={{ height: '100%' }}>
          {loading && <LinearLoader />}
          <Map
            features={[]}
            type="Grow Your Sphere"
            locations={[]}
            polygons={polygonLocations}
            marker={f => (
              <MapMarker color={getIconBuzzColor(f)} isSelected={useSelectedListingMarker} f={f} />
            )}
            popup={f => <MapPopup feature={f} />}
            tooltip={f => <MapToolTip feature={f} />}
          />
        </div>
      </div>
    </div>
  );
};
