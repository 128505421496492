import React from 'react';

import { GridBox, Text } from '@eltoro-ui/components';

import uploadIcon from 'assets/Images/icon-shape.png';

import './UploadAreaLayout.scss';

interface UploadRestrictions {
  fileSize: string;
  fileTypes: string;
  dimensions?: string;
  duration?: string;
}

interface UploadDetails {
  header: string;
  creativeLibrary: boolean;
  uploadRestrictions?: UploadRestrictions;
}

/* eslint-disable-next-line no-shadow */
export enum UploadTypes {
  mailerFront = 'mailerFront',
  mailerBack = 'mailerBack',
  banner = 'banner',
  video = 'video',
  csv = 'csv',
}

function uploadDetails(type: UploadTypes, multiple: boolean): UploadDetails {
  switch (type) {
    case UploadTypes.mailerFront: {
      return {
        header: 'Upload Front of Mailer',
        creativeLibrary: true,
      };
    }
    case UploadTypes.mailerBack: {
      return {
        header: 'Upload Back of Mailer',
        creativeLibrary: true,
      };
    }
    case UploadTypes.banner: {
      return {
        header: 'Upload your Banner Creative file(s) here',
        creativeLibrary: true,
        uploadRestrictions: {
          fileSize: '150KB',
          fileTypes: 'PNG, JPEG, JPG, GIF',
        },
      };
    }
    case UploadTypes.video: {
      return {
        header: 'Upload your Video Creative file(s) here',
        creativeLibrary: false,
        uploadRestrictions: {
          fileSize: '100MB',
          fileTypes: 'MP4, MOV',
          dimensions: '400 x 225, 400 x 300, 480 x 360, 640 x 360, 1920 x 800, 1920 x 1080',
          duration: 'no longer than 1 minute',
        },
      };
    }
    case UploadTypes.csv: {
      return {
        header: multiple ? 'Upload your file(s) here' : 'Upload your file here',
        creativeLibrary: false,
      };
    }

    default: {
      return {
        header: 'Upload Front of Mailer',
        creativeLibrary: true,
      };
    }
  }
}

export const UploadAreaLayout = ({
  uploadType,
  multiple = true,
}: {
  uploadType:
    | UploadTypes.csv
    | UploadTypes.banner
    | UploadTypes.video
    | UploadTypes.mailerBack
    | UploadTypes.mailerFront;
  multiple?: boolean;
}) => {
  const details = uploadDetails(uploadType, multiple);
  return (
    <div
      className="UploadAreaLayout"
      style={{
        marginBottom: uploadType !== 'video' && uploadType !== 'banner' ? '21px' : '',
      }}
    >
      <img src={uploadIcon} alt="upload-icon" className="upload-icon" />

      <Text
        on="white"
        weight="bold"
        UNSAFE_className="UploadAreaLayout__title"
        UNSAFE_style={{
          color: '#277B94',
          fontFamily: 'Open Sans',
        }}
      >
        {details.header}
      </Text>
      {details.uploadRestrictions && (
        <GridBox justifyItems="center" gap="12px">
          <span className="upload-restrictions">
            Supported formats: {details.uploadRestrictions.fileTypes}
          </span>
          <span className="upload-restrictions">
            File size: under {details.uploadRestrictions.fileSize}
          </span>
          {details.uploadRestrictions.duration && (
            <span className="upload-restrictions">
              Duration: {details.uploadRestrictions.duration}
            </span>
          )}
          {details.uploadRestrictions.dimensions && (
            <span className="upload-restrictions">
              File dimensions: {details.uploadRestrictions.dimensions}
            </span>
          )}
        </GridBox>
      )}
    </div>
  );
};
