import React from 'react';

import { Button, FlexBox, Modal } from '@eltoro-ui/components';

import cross from 'assets/Images/cross.png';

import image from 'assets/creatives-confirmation/image.svg';
import brand_logo from 'assets/creatives-confirmation/brand-logo.svg';
import message_promotion from 'assets/creatives-confirmation/message-promotion.svg';
import call_to_action from 'assets/creatives-confirmation/call-to-action.svg';
import click_url from 'assets/creatives-confirmation/click-url.svg';

import './CreativesConfirmationModal.scss';

type CreativesConfirmationModalProps = {
  onClose: VoidFunction;
  onConfirm: VoidFunction;
};

export default function CreativesConfirmationModal({
  onClose,
  onConfirm,
}: CreativesConfirmationModalProps) {
  return (
    <Modal className="creatives-confirmation-modal">
      <img
        src={cross}
        onClick={onClose}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <FlexBox flexDirection="column" gap="2rem">
        <FlexBox flexDirection="column" gap="1.5rem">
          <FlexBox flexDirection="column" alignItems="center" gap="0.5rem">
            <span className="creatives-confirmation-modal-title">
              Are you sure your creatives meet all the necessary branding requirements?
            </span>
            <span className="creatives-confirmation-modal-description">
              To meet exchange standards and pass audits, creatives must match the landing page
              branding with a logo, icon, or organization name. Successful ads often include:
            </span>
          </FlexBox>
          <FlexBox flexWrap="wrap" justifyContent="center" gap="30px 32px">
            <FlexBox flexDirection="column" gap="8px" UNSAFE_className="requirement-wrapper">
              <img src={image} alt="requirement-pic" />
              <span className="requirements-title">Image</span>
            </FlexBox>
            <FlexBox flexDirection="column" gap="8px" UNSAFE_className="requirement-wrapper">
              <img src={brand_logo} alt="requirement-pic" />
              <span className="requirements-title">Branding and/or logo</span>
            </FlexBox>
            <FlexBox flexDirection="column" gap="8px" UNSAFE_className="requirement-wrapper">
              <img src={message_promotion} alt="requirement-pic" />
              <span className="requirements-title">Message or promotion</span>
            </FlexBox>
            <FlexBox flexDirection="column" gap="8px" UNSAFE_className="requirement-wrapper">
              <img src={call_to_action} alt="requirement-pic" />
              <span className="requirements-title">Call-to-action</span>
            </FlexBox>
            <FlexBox flexDirection="column" gap="8px" UNSAFE_className="requirement-wrapper">
              <img src={click_url} alt="requirement-pic" />
              <span className="requirements-title">
                Click-Thru/Landing Page URL (ideally https secure)
              </span>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox justifyContent="center" gap="2rem">
          <Button
            kind="default"
            size="l"
            weight="bold"
            UNSAFE_className="contacts_btn"
            onClick={onClose}
          >
            Back to edit
          </Button>
          <Button kind="primary" size="l" weight="bold" onClick={onConfirm}>
            Yes, Continue
          </Button>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
}
