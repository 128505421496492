import React from 'react';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagic } from '@fortawesome/pro-light-svg-icons';

import { Button, FlexBox } from '@eltoro-ui/components';

import cross from 'assets/Images/cross.png';
import image1 from 'assets/Images/banner1.svg';
import image2 from 'assets/Images/banner2.svg';
import image3 from 'assets/Images/video1.svg';

import './AttachCreativeByType.scss';

type AttachCreativeByTypeProps = {
  typeBadge: 'banner' | 'video';
  closeModal: VoidFunction;
  onUploadFromPC: VoidFunction;
  onClickGenerateCreative: VoidFunction;
  onClickChooseFromCreativeLibrary: VoidFunction;
};
export default function AttachCreativeByType({
  typeBadge,
  closeModal,
  onUploadFromPC,
  onClickChooseFromCreativeLibrary,
  onClickGenerateCreative,
}: AttachCreativeByTypeProps) {
  const aiBannerGenerator = useFlag('ai-banner-generator');
  const { flagsReady } = useFlagsStatus();

  const info = {
    banner: {
      heading: 'Attach Banners',
      question1: 'Have your own banners?',
      question2: 'Need to Create New Ones?',
      img1: <img src={image1} alt="Attach banner" />,
      img2: <img src={image2} alt="Attach banner" />,
      footer1: (
        <div className="actions">
          <Button
            kind="default"
            size="l"
            UNSAFE_className="contacts_btn"
            weight="bold"
            onClick={() => {
              onUploadFromPC();
            }}
          >
            Upload from PC
          </Button>
          <button
            type="button"
            className="text-btn"
            onClick={() => {
              onClickChooseFromCreativeLibrary();
            }}
          >
            Choose from Library
          </button>
        </div>
      ),
      footer2: (
        <div className="actions">
          {flagsReady && aiBannerGenerator && (
            <Button
              kind="default"
              size="l"
              UNSAFE_className="contacts_btn"
              weight="bold"
              onClick={() => {}}
              iconLeft={<FontAwesomeIcon icon={faWandMagic} />}
            >
              Generate with AI
            </Button>
          )}
          <button
            type="button"
            className="text-btn"
            onClick={() => {
              onClickGenerateCreative();
            }}
          >
            Use Advanced Ad Builder
          </button>
        </div>
      ),
    },
    video: {
      heading: 'Attach Videos',
      question1: 'Need to upload from your PC?',
      question2: 'Already have in your library?',
      img1: <img src={image3} alt="Attach banner" />,
      img2: <img src={image1} alt="Attach banner" />,
      footer1: (
        <div className="actions">
          <Button
            kind="default"
            size="l"
            UNSAFE_className="contacts_btn"
            weight="bold"
            onClick={onUploadFromPC}
          >
            Upload from PC
          </Button>
        </div>
      ),
      footer2: (
        <div className="actions">
          <Button
            kind="default"
            size="l"
            UNSAFE_className="contacts_btn"
            weight="bold"
            onClick={onClickChooseFromCreativeLibrary}
          >
            Choose from Library
          </Button>
        </div>
      ),
    },
  };
  const { heading, question1, question2, img1, img2, footer1, footer2 } = info[typeBadge];
  return (
    <div className="attach-creative-modal-content">
      <button type="button" className="ConfirmPayment__cross" onClick={closeModal}>
        <img src={cross} alt="Close" />
      </button>
      <h3>{heading}</h3>
      <FlexBox gap="56px">
        <div className="attach-creative-item">
          <div className="item-container">
            {img1}
            <p className="desc">{question1}</p>
            {footer1}
          </div>
        </div>
        <div className="attach-creative-item">
          <div className="item-container">
            {img2}
            <p className="desc">{question2}</p>
            {footer2}
          </div>
        </div>
      </FlexBox>
    </div>
  );
}
