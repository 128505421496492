import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

import moment from 'moment';

import toast from 'react-hot-toast';

import { Button, MaxHeightContainer, Spacer } from '@eltoro-ui/components';
import {
  ImpressionValidator,
  Layout,
  Loading,
  PageHeader,
  ProgressStepper,
  SummaryCart,
} from 'Components';
import {
  CampaignCreationFooter,
  CampaignCreationWrapper,
  DateRangeSelector,
} from 'Pages/CampaignCreation/components';
import type { CampaignSetupParams } from 'Pages/CampaignCreation/components';
import CampaignErrorInfo from 'Pages/CampaignCreation/components/CampaignErrorInfo';
import {
  BANNER_CPM,
  VIDEO_CPM,
  MIN_IMPRESSIONS_COUNT,
  BANNER_FREQ,
  VIDEO_FREQ,
  DEFAULT_SUGGESTED_DATES,
  DEFAULT_MIN_FLIGHT_DATES,
} from 'Utils/constants';
import { adjustDates } from 'Utils/helpers';
import { getCampaignById } from 'Requests/Request_Methods/campaignMethods';

import type { CampaignType, AudienceType } from 'types';
import { CampaignStatusEnum, CampaignStep } from 'enums';

import './CampaignCheckout.scss';

export type campaignCostType = {
  campaign: CampaignType | null;
  startDate: Date;
  endDate: Date;
  minFlightDuration: number;
  bannerCPM: number;
  videoCPM: number;
  bannerFreq: number;
  videoFreq: number;
  suggestedDates: number;
  isDateSelected: boolean;
  isConfirmed: boolean;
  excludedAudiences: number[];
  changedAudiences: AudienceType[];
};

export const CampaignCheckout = (props: RouteComponentProps<CampaignSetupParams>) => {
  const { campaignId } = props.match.params;
  const history = useHistory();

  const { startDate: initialStartDate, endDate: initialEndDate } = useMemo(() => {
    return adjustDates(moment().toDate());
  }, []);

  const [campaignCostAccData, setCampaignCostAccData] = useState<campaignCostType>({
    campaign: null,
    startDate: initialStartDate,
    endDate: initialEndDate,
    minFlightDuration: DEFAULT_MIN_FLIGHT_DATES,
    suggestedDates: DEFAULT_SUGGESTED_DATES,
    bannerCPM: BANNER_CPM,
    videoCPM: VIDEO_CPM,
    bannerFreq: BANNER_FREQ,
    videoFreq: VIDEO_FREQ,
    excludedAudiences: [],
    changedAudiences: [],
    isDateSelected: false,
    isConfirmed: false,
  });

  useEffect(() => {
    const getCampaign = async () => {
      try {
        const { data: campaign } = await getCampaignById(campaignId);
        if (campaign) {
          if (campaign.status === CampaignStatusEnum.IN_REVIEW)
            history.replace(`/campaigns/${campaign.id}`);

          if (campaign.step === CampaignStep.DONE)
            if (
              campaign.status === CampaignStatusEnum.ERROR ||
              campaign.status === CampaignStatusEnum.CREATING
            )
              history.replace('/campaigns');

          const minDaysBanner = campaign.banner_count
            ? MIN_IMPRESSIONS_COUNT / (campaign.prospects_counts * campaignCostAccData.bannerFreq)
            : 0;
          const minDaysVideo = campaign.video_count
            ? MIN_IMPRESSIONS_COUNT / (campaign.prospects_counts * campaignCostAccData.videoFreq)
            : 0;

          const overalMinDays = Math.max(Math.ceil(minDaysBanner), Math.ceil(minDaysVideo));

          let { suggestedDates, minFlightDuration } = campaignCostAccData;

          if (suggestedDates <= overalMinDays) {
            suggestedDates = overalMinDays;
          }

          if (suggestedDates >= overalMinDays) {
            minFlightDuration = overalMinDays;
          }

          const { endDate } = adjustDates(moment().toDate(), suggestedDates);
          setCampaignCostAccData(prevData => ({
            ...prevData,
            campaign,
            endDate, // make date range by default 15 days
            suggestedDates,
            minFlightDuration,
          }));
        }
      } catch (e) {
        toast.error(e);
      }
    };
    getCampaign();
    return () => {
      setCampaignCostAccData(prevData => ({
        ...prevData,
        campaign: null,
      }));
    };
  }, []);

  const onStartDateChange = (newDate: Date) => {
    setCampaignCostAccData(prevData => ({
      ...prevData,
      startDate: newDate,
    }));
  };

  const onEndDateChange = (newDate: Date) => {
    setCampaignCostAccData(prevData => ({
      ...prevData,
      endDate: newDate,
      isDateSelected: true,
    }));
  };

  const days = moment(campaignCostAccData.endDate)
    .add({ days: 1 })
    .diff(moment(campaignCostAccData.startDate), 'days');

  return (
    <div className="CampaignCheckout">
      <MaxHeightContainer
        fullHeight
        header={
          <div>
            <PageHeader
              title={campaignCostAccData.campaign?.name}
              UNSAFE_CLASSNAME="CampaignSetup__header"
              UNSAFE_CLASSNAME_GRID="CampaignSetup__titleSpace"
              UNSAFE_CLASSNAME_TITLE="CampaignCheckout__title"
              subTitle=" "
              actions={[
                <ProgressStepper
                  key="progress-stepper"
                  tagNames={['Audience', 'Creative', 'Checkout']}
                  activeStep={3}
                />,
              ]}
              actionsStyle="CampaignSetup__progress"
            />
          </div>
        }
        footer={
          <CampaignCreationFooter>
            <Button
              size="l"
              UNSAFE_className="campaignBack"
              onClick={() => {
                history.replace(`/create-campaign/${campaignCostAccData.campaign?.id}/creatives`);
              }}
            >
              Back
            </Button>
            <Spacer />
          </CampaignCreationFooter>
        }
      >
        <Layout>
          {campaignCostAccData.campaign?.status === CampaignStatusEnum.PAYMENT_FAILED &&
            campaignCostAccData.campaign?.step === CampaignStep.CHECKOUT && (
              <CampaignErrorInfo
                title="There were some issues with the billing and checkout process."
                subTitle="Please verify your billing information and try again."
              />
            )}
          <CampaignCreationWrapper>
            <div className="CampaignCheckout__content">
              <DateRangeSelector
                startDate={campaignCostAccData.startDate}
                endDate={campaignCostAccData.endDate}
                minFlightDuration={
                  campaignCostAccData.minFlightDuration < DEFAULT_MIN_FLIGHT_DATES
                    ? DEFAULT_MIN_FLIGHT_DATES
                    : campaignCostAccData.minFlightDuration
                }
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
                message={(duration: number) => {
                  if (
                    duration < campaignCostAccData.minFlightDuration &&
                    campaignCostAccData.minFlightDuration >= DEFAULT_MIN_FLIGHT_DATES
                  ) {
                    let daysCount = campaignCostAccData.minFlightDuration;

                    return <ImpressionValidator days={Math.round(daysCount)} />;
                  }

                  if (
                    campaignCostAccData.isDateSelected &&
                    campaignCostAccData.minFlightDuration < DEFAULT_MIN_FLIGHT_DATES &&
                    duration < DEFAULT_MIN_FLIGHT_DATES
                  ) {
                    return <ImpressionValidator days={Math.round(DEFAULT_MIN_FLIGHT_DATES)} />;
                  }

                  return (
                    <p className="DateRangeSelector__count_days">
                      Your ad will be displayed these
                      <br />
                      <span>{`${duration} ${duration <= 1 ? 'day' : 'days'}`}</span>
                    </p>
                  );
                }}
              />
            </div>
            {campaignCostAccData.campaign ? (
              <SummaryCart
                isCheckout
                days={days}
                campaignCostAccData={campaignCostAccData}
                setCampaignCostAccData={setCampaignCostAccData}
              />
            ) : (
              <Loading />
            )}
          </CampaignCreationWrapper>
        </Layout>
      </MaxHeightContainer>
    </div>
  );
};
