import React, { useEffect, useMemo, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { v4 as uuidv4 } from 'uuid';

import toast from 'react-hot-toast';
import classnames from 'classnames';
import Tooltip from 'rc-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { Button, FlexBox, GridBox, MaxHeightContainer, Modal, Text } from '@eltoro-ui/components';
import { CreativeGen } from '@eltoro-ui/creative_generator';
import { Layout, Loading, PageHeader, ProgressStepper, SummaryCart } from 'Components';

import type { CampaignSetupParams } from 'Pages/CampaignCreation/components';
import { CampaignCreationFooter, CampaignCreationWrapper } from 'Pages/CampaignCreation/components';
import {
  AttachedCreatives,
  CreativeCampaignModal,
  CreativeTypeBadge,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components';
import ChooseFromCreativesLibraryModal from 'Pages/CampaignCreation/components/CampaignCreatives/_components/ChooseFromCreativesLibraryModal';
import CampaignErrorInfo from 'Pages/CampaignCreation/components/CampaignErrorInfo';
import CampaignExamplesWidget from 'Components/CampaignExamplesWidget';

import GroupIcon from 'assets/Images/Group339.png';
import SubtractIcon from 'assets/Images/Subtract.png';

import { setSpinner } from 'Redux/actions';
import { getCampaignById, saveCampaignCreative } from 'Requests/Request_Methods/campaignMethods';

import type { CampaignType, CreativeActionUrls, CreativeType, FileUploadType } from 'types';
import { CampaignStatusEnum, CampaignStep, CreativeStatusEnum, CreativeTypeEnum } from 'enums';

import './CampaignCreatives.scss';
import CreativesConfirmationModal from './_components/CreativesConfirmationModal';

export type CreativeUrls = { video_url: boolean; banner_url: boolean };
export const CampaignCreatives = (props: RouteComponentProps<CampaignSetupParams>) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const campaignTemplateInfoBannerFlag = useFlag('campaign-template-info-banner');
  const { flagsReady } = useFlagsStatus();

  const [selectedCreativeType, setSelectedCreativeType] = useState<CreativeTypeEnum | null>(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [campaign, setCampaign] = useState<CampaignType | null>(null);

  const { campaignId } = props.match.params;
  const [showCreativeGen, setShowCreativeGen] = useState(false);
  const [status, setstatus] = useState('pending');
  const [urls, setUrls] = useState<CreativeUrls>({
    video_url: false,
    banner_url: false,
  });

  const [uploadCreativeActionsOpen, setUploadCreativeActionsOpen] = useState<CreativeActionUrls>({
    [CreativeTypeEnum.BANNER]: false,
    [CreativeTypeEnum.VIDEO]: false,
  });
  const defaultCreative = 'banner';

  const [creativeUUIDs, setCreativeUUIDs] = useState<string[]>([]);
  const [generatedBanners, setGeneratedBanners] = useState<CreativeType[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const [creativesConfirmationOpen, setCreativeConfirmationOpen] = useState(false);

  const [creativesLibraryModalOpen, setCreativesLibraryModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const getCampaign = async () => {
      try {
        setstatus('pending');
        const { data } = await getCampaignById(campaignId);
        if (data) {
          if (data.status === CampaignStatusEnum.IN_REVIEW)
            history.replace(`/campaigns/${data.id}`);

          if (data.step === CampaignStep.DONE)
            if (
              data.status === CampaignStatusEnum.ERROR ||
              data.status === CampaignStatusEnum.CREATING
            )
              history.replace('/campaigns');

          setUrls({
            video_url: !!data?.video_count,
            banner_url: !!data?.banner_count,
          });

          setCampaign(data);
          if (data.creatives) setCreativeUUIDs(data.creatives.map(creative => creative.id));
        }
      } catch (e) {
        toast.error(e);
      } finally {
        setstatus('success');
      }
    };
    getCampaign();

    return () => {
      setCampaign(null);
    };
  }, []);

  const [nextClicked, setnextClicked] = useState(true);

  useEffect(() => {
    if (
      campaign?.creatives?.find(({ content_type }) => content_type === 'video') &&
      urls?.video_url
    ) {
      setnextClicked(false);
    } else if (
      campaign?.creatives?.find(({ content_type }) => content_type === 'banner') &&
      urls?.banner_url
    ) {
      setnextClicked(false);
    }

    if (
      (campaign?.creatives?.find(({ content_type }) => content_type === 'banner') &&
        !urls?.banner_url) ||
      (campaign?.creatives?.find(({ content_type }) => content_type === 'video') &&
        !urls?.video_url)
    ) {
      setnextClicked(true);
    }

    if (!campaign?.creatives?.length) {
      setnextClicked(true);
    }
  }, [campaign, urls]);

  const [isSubmit, setisSubmit] = useState(false);

  const returnHttpsPrefix = (value: string | undefined) => {
    if (!value) return undefined;

    if (!value.includes('https://')) {
      if (!value.includes('www')) {
        return `https://www.${value}`;
      }
      return `https://${value}`;
    }
    return value;
  };

  const handleNextClick = async () => {
    const formData = new FormData();
    setisSubmit(true);

    campaign?.creatives.map(item => (item.click_url = returnHttpsPrefix(item.click_url)));

    const dataNew = {
      campaign_id: +campaignId,
      video_click_url: campaign?.creatives.find(item => item.content_type === 'video')?.click_url,
      banner_click_url: campaign?.creatives.find(item => item.content_type === 'banner')?.click_url,
      creative_uuids: creativeUUIDs,
      generated_banners: generatedBanners.map(item => item.thumb),
    };

    formData.append('body', JSON.stringify(dataNew));
    if (!campaign?.creatives.length) {
      toast.error('Please add a creative');
      setnextClicked(false);
    } else {
      files.forEach(file => formData.append('files', file));

      dispatch(setSpinner(true));

      setnextClicked(true);
      saveCampaignCreative(formData)
        .then(res => {
          if (res.data) {
            history.push({
              pathname: `/create-campaign/${campaignId}/calculate`,
            });
          }
          dispatch(setSpinner(false));
          setnextClicked(false);
        })
        .catch(err => {
          if ('detail' in err) toast.error(err.detail);
          else toast.error('something went wrong');
          dispatch(setSpinner(false));
        });
    }
  };

  const checkClickUrl = (typeModal: 'banner' | 'video') => {
    if (typeModal === 'banner') {
      setUrls(prev => ({ ...prev, banner_url: true }));
      if (!urls.banner_url)
        return campaign?.creatives.find(item => item.content_type === 'video')?.click_url;
      return campaign?.creatives.find(item => item.content_type === 'banner')?.click_url;
    }

    setUrls(prev => ({ ...prev, video_url: true }));
    if (!urls.video_url)
      return campaign?.creatives.find(item => item.content_type === 'banner')?.click_url;
    return campaign?.creatives.find(item => item.content_type === 'video')?.click_url;
  };

  const objectCreatives = (files: FileUploadType, typeModal: CreativeTypeEnum) => {
    return files.acceptedFiles.map(file => {
      return {
        id: uuidv4(),
        click_url: checkClickUrl(typeModal),
        text: file.name,
        thumb: URL.createObjectURL(file),
        thumb_url: null,
        content_type: typeModal,
        status: CreativeStatusEnum.CREATING,
        file_specs: file.file_specs,
        file_size: file.size,
      };
    });
  };

  const handleCloseCreativeActionsModal = (creativeType: CreativeTypeEnum, isOpen: boolean) => {
    setUploadCreativeActionsOpen(prev => {
      return { ...prev, [creativeType]: isOpen };
    });
  };

  const onCancelChooseFromLibrary = () => {
    setCreativesLibraryModalOpen(false);
  };

  // Each ad asset must have branding that matches the landing page with a logo, icon, or organization name.
  //
  //   Successful digital advertising creatives usually include:
  //   Image: Agent headshot, listing
  // Brokerage information: logo, name or icon
  // Message or Promotion
  // Call-to-action
  // Landing Page URL

  const creativesWithStatusError = useMemo<boolean>(
    () =>
      campaign?.creatives.some(
        creative => creative?.status === CreativeStatusEnum.ERROR || !creative.click_url?.length
      ) ?? false,
    [campaign]
  );

  const CampaignInfoBanner = useMemo(() => {
    if (!flagsReady) return null;
    if (campaignTemplateInfoBannerFlag) return <CampaignExamplesWidget redirectToLens={false} />;
    return null;
  }, [campaignTemplateInfoBannerFlag, flagsReady]);

  const creativesTooltipOverlay = (
    <FlexBox flexDirection="column" gap="0.5rem">
      <span>
        Each ad asset must have branding that matches the landing page with a logo, icon, or
        organization name.
      </span>
      <FlexBox flexDirection="column">
        <span>Successful digital advertising creatives usually include:</span>
        <ul style={{ paddingLeft: '1rem' }}>
          <li>Image: Agent headshot, listing</li>
          <li>Brokerage information: logo, name or icon</li>
          <li>Message or Promotion</li>
          <li>Call-to-action</li>
          <li>Landing Page URL</li>
        </ul>
      </FlexBox>
    </FlexBox>
  );

  return (
    <div className="CampaignCreatives">
      <MaxHeightContainer
        fullHeight
        header={
          <PageHeader
            UNSAFE_CLASSNAME_GRID="CampaignSetup__titleSpace"
            UNSAFE_CLASSNAME="CampaignSetup__header"
            UNSAFE_CLASSNAME_TITLE="CampaignCheckout__title "
            title={campaign?.name}
            subTitle=""
            actionsStyle="CampaignSetup__progress"
            actions={[
              <ProgressStepper
                key="progress-stepper"
                pathset={() => {
                  history.push(`/create-campaign/${campaign?.id}/setup`);
                }}
                tagNames={['Audience', 'Creative', 'Checkout']}
                activeStep={2}
              />,
            ]}
          />
        }
        footer={
          <CampaignCreationFooter>
            <Button
              size="l"
              UNSAFE_className="campaignBack"
              onClick={() =>
                selectedCreativeType === 'banner'
                  ? setSelectedCreativeType(null)
                  : history.push({
                      pathname: `/create-campaign/${campaignId}/setup`,
                    })
              }
            >
              Back
            </Button>
            <div className="CampaignSetup__next-button">
              <Button
                size="l"
                UNSAFE_className="CampaignSetup__campaign_use_total_prospects"
                onClick={() => setCreativeConfirmationOpen(true)}
                disabled={nextClicked || creativesWithStatusError}
              >
                Next
              </Button>
            </div>
          </CampaignCreationFooter>
        }
      >
        {creativesConfirmationOpen && (
          <CreativesConfirmationModal
            onConfirm={handleNextClick}
            onClose={() => setCreativeConfirmationOpen(false)}
          />
        )}
        <Layout>
          {campaign?.status === CampaignStatusEnum.ERROR &&
            campaign?.step === CampaignStep.CREATIVE &&
            creativesWithStatusError && (
              <CampaignErrorInfo
                title="There were some issues with the attached creative(s)."
                subTitle="Please remove the creatives with errors and/or select different creatives."
              />
            )}
          <CampaignCreationWrapper>
            {status === 'pending' ? (
              <Loading />
            ) : (
              <>
                <GridBox gap="1rem" UNSAFE_className="creatives_main_div">
                  {showCreativeGen && (
                    <Modal offClick={() => setShowCreativeGen(false)}>
                      <CreativeGen
                        onComplete={base64s => {
                          const base64Creatives = base64s.map(base64 => ({
                            id: uuidv4(),
                            click_url: checkClickUrl(defaultCreative),
                            text: null,
                            thumb: base64.handleDataURL,
                            thumb_url: base64.handleDataURL,
                            content_type: CreativeTypeEnum.BANNER,
                            status: CreativeStatusEnum.CREATING,
                            file_specs: null,
                            file_size: null,
                          }));

                          setCampaign(campaign =>
                            campaign
                              ? {
                                  ...campaign,
                                  creatives: [...campaign?.creatives, ...base64Creatives],
                                }
                              : null
                          );
                          setGeneratedBanners(prevBase64Creatives => [
                            ...prevBase64Creatives,
                            ...base64Creatives,
                          ]);
                          setSelectedCreativeType(null);
                          setShowCreativeGen(false);
                          handleCloseCreativeActionsModal(CreativeTypeEnum.BANNER, false);
                        }}
                        defaultCreativeType={defaultCreative}
                        onClose={() => setShowCreativeGen(false)}
                        width="95vw"
                        height="90vh"
                      />
                    </Modal>
                  )}
                  {/* Conditionals to open modals for specific creative types */}
                  {uploadModalOpen && selectedCreativeType && (
                    <CreativeCampaignModal
                      onClickOutside={() => {
                        setSelectedCreativeType(null);
                        setUploadModalOpen(false);
                      }}
                      type={selectedCreativeType}
                      onSubmit={(files: FileUploadType, typeModal?: CreativeTypeEnum) => {
                        setnextClicked(true);
                        if (typeModal === CreativeTypeEnum.VIDEO) {
                          setFiles(prevFiles => [...prevFiles, ...files.acceptedFiles]);
                          setCampaign(campaign =>
                            campaign
                              ? {
                                  ...campaign,
                                  creatives: [
                                    ...campaign?.creatives,
                                    ...objectCreatives(files, typeModal),
                                  ],
                                }
                              : null
                          );
                        }
                        if (typeModal === CreativeTypeEnum.BANNER) {
                          setFiles(prevFiles => [...prevFiles, ...files.acceptedFiles]);
                          setCampaign(campaign =>
                            campaign
                              ? {
                                  ...campaign,
                                  creatives: [
                                    ...campaign?.creatives,
                                    ...objectCreatives(files, typeModal),
                                  ],
                                }
                              : null
                          );
                          setSelectedCreativeType(null);
                        }
                        if (typeModal) handleCloseCreativeActionsModal(typeModal, false);
                      }}
                    />
                  )}
                  <div className="CampaignCreatives__choose-creatives">
                    <FlexBox alignItems="center" gap="8px">
                      <Text
                        UNSAFE_className="CampaignCreatives__choose_title"
                        tag="div"
                        on="white"
                        size="xxl"
                      >
                        Creatives
                      </Text>
                      <Tooltip
                        placement="topLeft"
                        trigger="hover"
                        overlayClassName={classnames('audience-tooltip', 'creative-description')}
                        showArrow
                        align={{
                          offset: [-20, -3],
                          targetOffset: [-6, 0],
                        }}
                        overlay={creativesTooltipOverlay}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 24 }} />
                      </Tooltip>
                    </FlexBox>
                    {CampaignInfoBanner}
                    <div
                      style={{
                        display: 'grid',
                      }}
                      className={classnames('CampaignCreatives__types')}
                    >
                      <CreativeTypeBadge
                        onClick={() => {
                          setSelectedCreativeType(CreativeTypeEnum.BANNER);
                          setUploadModalOpen(true);
                        }}
                        onClickGenerateCreative={() => {
                          setShowCreativeGen(true);
                        }}
                        onClickChooseFromCreativeLibrary={() => {
                          setSelectedCreativeType(CreativeTypeEnum.BANNER);
                          setCreativesLibraryModalOpen(true);
                        }}
                        handleCloseCreativeActionsModal={handleCloseCreativeActionsModal}
                        uploadCreativeActionsOpen={uploadCreativeActionsOpen}
                        text="Add Banner"
                        bannerIcon={GroupIcon}
                        typeBadge={CreativeTypeEnum.BANNER}
                        UNSAFE_className="CampaignSetup__Choose_your_Creative"
                      />
                      <CreativeTypeBadge
                        UNSAFE_className="flex CampaignSetup__Choose_your_Creative"
                        onClick={() => {
                          setSelectedCreativeType(CreativeTypeEnum.VIDEO);
                          setUploadModalOpen(true);
                        }}
                        onClickChooseFromCreativeLibrary={() => {
                          setSelectedCreativeType(CreativeTypeEnum.VIDEO);
                          setCreativesLibraryModalOpen(true);
                        }}
                        handleCloseCreativeActionsModal={handleCloseCreativeActionsModal}
                        uploadCreativeActionsOpen={uploadCreativeActionsOpen}
                        text="Add Video"
                        typeBadge={CreativeTypeEnum.VIDEO}
                        bannerIcon={SubtractIcon}
                      />
                    </div>
                  </div>
                  {campaign && campaign?.creatives?.length > 0 && (
                    <AttachedCreatives
                      isSubmit={isSubmit}
                      creatives={campaign?.creatives}
                      campaign={campaign}
                      setCampaign={setCampaign}
                      setCreativeUUIDs={setCreativeUUIDs}
                      creativeUUIDs={creativeUUIDs}
                      setGeneratedBanners={setGeneratedBanners}
                      setFiles={setFiles}
                      setnextClicked={setnextClicked}
                      urls={urls}
                      setUrls={setUrls}
                    />
                  )}
                </GridBox>
                {campaign && <SummaryCart campaignCostAccData={{ campaign }} />}
                <ChooseFromCreativesLibraryModal
                  campaignCreatives={campaign?.creatives ?? []}
                  type={selectedCreativeType}
                  open={creativesLibraryModalOpen}
                  onCancel={onCancelChooseFromLibrary}
                  onConfirm={(selectedCreatives, typeModal) => {
                    setnextClicked(true);
                    setCreativeUUIDs(creativeUUIDs => [
                      ...creativeUUIDs,
                      ...selectedCreatives.map(({ creative_uuid }) => creative_uuid),
                    ]);
                    setCampaign(campaign =>
                      campaign
                        ? {
                            ...campaign,
                            creatives: [
                              ...campaign?.creatives,
                              ...selectedCreatives.map(creative => ({
                                id: creative.creative_uuid,
                                click_url: checkClickUrl(typeModal),
                                text: `${creative.name}.${creative.file_type}`,
                                presigned_url: creative.presigned_url,
                                thumb_presigned_url: creative.thumb_presigned_url,
                                thumb: creative.thumb_presigned_url || creative.presigned_url,
                                thumb_url: creative.thumb_presigned_url || creative.presigned_url,
                                content_type: creative.creative_type,
                                status: CreativeStatusEnum.CREATING,
                                file_specs: creative.file_specs,
                                file_size: creative.file_size,
                              })),
                            ],
                          }
                        : null
                    );
                    if (selectedCreativeType)
                      handleCloseCreativeActionsModal(selectedCreativeType, false);
                  }}
                />
              </>
            )}
          </CampaignCreationWrapper>
        </Layout>
      </MaxHeightContainer>
    </div>
  );
};
