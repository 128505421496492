import sell_your_listing_faster from 'assets/Images/prospects_lg_2.png';
import talk_to_your_sphere from 'assets/Images/prospects_lg_1_active.png';
import grow_your_sphere from 'assets/Images/prospects_lg_3_active.png';
import likely_sellers from 'assets/Images/likely-sellers-active.png';
import virtual_just_listed_just_sold from 'assets/Images/prospects_lg_6_active.png';
import digital_farming from 'assets/Images/digital_farming.png';

// creatives
// ---- sell your listing faster, likely-sellers, virtual just listed/just sold -----
import sell_your_listing_faster_300_250 from 'assets/lenses-creatives-examples/sell-your-listing-faster/300-250.png';
import sell_your_listing_faster_300_250_1 from 'assets/lenses-creatives-examples/sell-your-listing-faster/300-250_1.png';
import sell_your_listing_faster_320_50 from 'assets/lenses-creatives-examples/sell-your-listing-faster/320-50.png';
import sell_your_listing_faster_320_100 from 'assets/lenses-creatives-examples/sell-your-listing-faster/320-100.png';
import sell_your_listing_faster_300_600 from 'assets/lenses-creatives-examples/sell-your-listing-faster/300-600.png';
import sell_your_listing_faster_160_600 from 'assets/lenses-creatives-examples/sell-your-listing-faster/160-600.png';

// ---- talk to your sphere, grow your sphere, digital farming -----
import talk_to_your_sphere_300_250 from 'assets/lenses-creatives-examples/talk-to-your-sphere/300-250.png';
import talk_to_your_sphere_300_250_1 from 'assets/lenses-creatives-examples/talk-to-your-sphere/300-250_1.png';
import talk_to_your_sphere_728_90 from 'assets/lenses-creatives-examples/talk-to-your-sphere/728-90.png';
import talk_to_your_sphere_320_100 from 'assets/lenses-creatives-examples/talk-to-your-sphere/320-100.png';
import talk_to_your_sphere_300_600 from 'assets/lenses-creatives-examples/talk-to-your-sphere/300-600.png';
import talk_to_your_sphere_160_600 from 'assets/lenses-creatives-examples/talk-to-your-sphere/160-600.png';

export const lenses = [
  {
    label: 'Sell Your Listing Faster',
    link: '/prospects/sell-your-listing-faster',
    img_link: sell_your_listing_faster,
    description_1:
      'Showcase your listing to buyers interested in properties like it. Use photos of your listing Include a link to the listing in the click-thru URL.',
    creatives: {
      '300-250': sell_your_listing_faster_300_250,
      '300-250_1': sell_your_listing_faster_300_250_1,
      '320-50': sell_your_listing_faster_320_50,
      '320-100': sell_your_listing_faster_320_100,
      '300-600': sell_your_listing_faster_300_600,
      '160-600': sell_your_listing_faster_160_600,
    },
  },
  {
    label: 'Talk To Your Sphere',
    link: '/prospects/talk-to-your-sphere',
    img_link: talk_to_your_sphere,
    description_1:
      'Showcase your brand and increase exposure of your business. Use photos of yourself Include a click-thru URL to a page about your team or business unit.',
    creatives: {
      '728-90': talk_to_your_sphere_728_90,
      '300-250': talk_to_your_sphere_300_250,
      '300-250_1': talk_to_your_sphere_300_250_1,
      '320-100': talk_to_your_sphere_320_100,
      '300-600': talk_to_your_sphere_300_600,
      '160-600': talk_to_your_sphere_160_600,
    },
  },
  {
    label: 'Grow Your Sphere',
    link: '/prospects/grow-your-sphere',
    img_link: grow_your_sphere,
    description_1:
      'Showcase your brand and increase exposure of your business to people who may know you. Use photos of yourself, Include a click-thru URL to a page about your team or business unit.',
    creatives: {
      '728-90': talk_to_your_sphere_728_90,
      '300-250': talk_to_your_sphere_300_250,
      '300-250_1': talk_to_your_sphere_300_250_1,
      '320-100': talk_to_your_sphere_320_100,
      '300-600': talk_to_your_sphere_300_600,
      '160-600': talk_to_your_sphere_160_600,
    },
  },
  {
    label: 'Likely Sellers',
    link: '/prospects/likely-sellers',
    img_link: likely_sellers,
    description_1:
      'Put your business at for front of sellers. Show off your listing history/wins in the area you are targeting to. Market your brand include a click-thru URL to a page about your past transactions in a certain area, or your brand.',
    creatives: {
      '728-90': talk_to_your_sphere_728_90,
      '300-250': talk_to_your_sphere_300_250,
      '300-250_1': talk_to_your_sphere_300_250_1,
      '320-100': talk_to_your_sphere_320_100,
      '300-600': talk_to_your_sphere_300_600,
      '160-600': talk_to_your_sphere_160_600,
    },
  },
  {
    label: 'Virtual Just Listed/Just Sold',
    link: '/prospects/virtual-just-listed-just-sold',
    img_link: virtual_just_listed_just_sold,
    description_1: 'Digital postcard/door knocking.',
    description_2: 'Include information about the listing.',
    creatives: {
      '300-250': sell_your_listing_faster_300_250,
      '300-250_1': sell_your_listing_faster_300_250_1,
      '320-50': sell_your_listing_faster_320_50,
      '320-100': sell_your_listing_faster_320_100,
      '300-600': sell_your_listing_faster_300_600,
      '160-600': sell_your_listing_faster_160_600,
    },
  },
  {
    label: 'Digital Farming',
    link: '/prospects/digital-farming',
    img_link: digital_farming,
    description_1:
      'Expand your marketing efforts by reaching out to prospects within new areas. Include a click-thru URL to a page about your team or business unit.',
    creatives: {
      '728-90': talk_to_your_sphere_728_90,
      '300-250': talk_to_your_sphere_300_250,
      '300-250_1': talk_to_your_sphere_300_250_1,
      '320-100': talk_to_your_sphere_320_100,
      '300-600': talk_to_your_sphere_300_600,
      '160-600': talk_to_your_sphere_160_600,
    },
  },
];

export type LensType = (typeof lenses)[number];
