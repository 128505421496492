import React from 'react';
import { useSelector } from 'react-redux';

import Tooltip from 'rc-tooltip';
import classNames from 'classnames';

import { Button, FlexBox } from '@eltoro-ui/components';

import type { LensType } from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/constants';
import CreativesContent from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/components/CampaignModalContent/components/CreativesContent';

import type { TRootState } from 'types';

export interface CampaignModalContentProps {
  selectedLens: LensType;
  redirectToLens: boolean;
}

const premiumLenses = ['Grow Your Sphere', 'Likely Sellers'];

export default function CampaignModalContent({
  selectedLens,
  redirectToLens,
}: CampaignModalContentProps) {
  const { userData } = useSelector((state: TRootState) => state.userReducer);

  const disableRedirectButton =
    userData.sub_name !== 'premium' && premiumLenses.includes(selectedLens.label);

  const overlay =
    selectedLens.label === premiumLenses[1]
      ? 'Upgrade your account to identify households who are likely to sell their house, turning them into potential prospects.'
      : selectedLens.label === premiumLenses[0]
      ? 'Upgrade your account to expand your sphere by engaging with households observed at your contact’s homes.'
      : null;

  return (
    <div className="campaign-modal-content">
      <FlexBox flexDirection="column" justifyContent="space-between">
        <FlexBox UNSAFE_className="campaign-modal-content-inner" flexDirection="column" gap="20px">
          <FlexBox flexDirection="column" gap="12px">
            <FlexBox alignItems="center" gap="8px">
              <img className="lens-img" src={selectedLens.img_link} alt={selectedLens.label} />
              <span className="lens-title">{selectedLens.label}</span>
            </FlexBox>
            <FlexBox flexDirection="column" UNSAFE_style={{ maxWidth: 640 }}>
              <span className="lens-description">{selectedLens.description_1}</span>
              {selectedLens.description_2 && (
                <span className="lens-description">{selectedLens.description_2}</span>
              )}
            </FlexBox>
          </FlexBox>
          <CreativesContent selectedLens={selectedLens} />
        </FlexBox>
        {redirectToLens && (
          <Tooltip
            placement="top"
            trigger="hover"
            overlayClassName={classNames('audience-tooltip', 'save-audience-info')}
            showArrow
            align={{
              offset: [-15, -3],
              targetOffset: [-6, 0],
            }}
            overlay={overlay}
            {...(disableRedirectButton ? {} : { visible: false })}
          >
            <Button
              kind="primary"
              weight="bold"
              size="l"
              to={selectedLens.link}
              disabled={disableRedirectButton}
            >
              Create Audience Using this Lens
            </Button>
          </Tooltip>
        )}
      </FlexBox>
    </div>
  );
}
