import React from 'react';

import classNames from 'classnames';

import { FlexBox } from '@eltoro-ui/components';

import { lenses } from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/constants';
import type { LensType } from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/constants';

interface CampaignModalSiderProps {
  selectedLens: LensType;
  onSelectLens: (lens: LensType) => VoidFunction;
}

export default function CampaignModalSider({
  selectedLens,
  onSelectLens,
}: CampaignModalSiderProps) {
  return (
    <div className="campaign-modal-sider">
      <FlexBox flexDirection="column" gap="32px">
        {lenses.map(lens => (
          <button
            key={lens.label}
            onClick={onSelectLens(lens)}
            className={classNames('lens-item', {
              'lens-item-selected': selectedLens.label === lens.label,
            })}
          >
            <span className="lens-item-title">{lens.label}</span>
          </button>
        ))}
      </FlexBox>
    </div>
  );
}
