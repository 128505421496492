import React from 'react';

import { FlexBox } from '@eltoro-ui/components';

import { CampaignModalContentProps } from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/components/CampaignModalContent';

const labels = ['Sell Your Listing Faster', 'Virtual Just Listed/Just Sold'];

interface CreativesContentProps extends Pick<CampaignModalContentProps, 'selectedLens'> {}

export default function CreativesContent({ selectedLens }: CreativesContentProps) {
  if (labels.includes(selectedLens.label))
    return (
      <FlexBox flexDirection="column" gap="2rem">
        <div className="creatives-container">
          <div className="grid-column">
            <div className="creative-item item-300-250">
              <div className="creative-container">
                <img src={selectedLens.creatives['300-250']} alt="300-250" />
              </div>
              <div className="creative-dimensions">300 x 250</div>
            </div>
            <div className="creative-item item-300-250">
              <div className="creative-container">
                <img src={selectedLens.creatives['300-250_1']} alt="300-250-1" />
              </div>
              <div className="creative-dimensions">300 x 250</div>
            </div>
            <div className="creative-item item-320-50">
              <div className="creative-container">
                <img src={selectedLens.creatives['320-50']} alt="320-50" />
              </div>
              <div className="creative-dimensions">300 x 50</div>
            </div>
          </div>
          <div className="grid-column">
            <div className="creative-item item-320-100">
              <div className="creative-container">
                <img src={selectedLens.creatives['320-100']} alt="320-100" />
              </div>
              <div className="creative-dimensions">320 x 100</div>
            </div>
            <div className="creative-item item-300-600">
              <div className="creative-container">
                <img src={selectedLens.creatives['300-600']} alt="300-600" />
              </div>
              <div className="creative-dimensions">300 x 600</div>
            </div>
          </div>
          <div className="grid-vertical">
            <div className="creative-item item-160-600">
              <div className="creative-container">
                <img src={selectedLens.creatives['160-600']} alt="160-600" />
              </div>
              <div className="creative-dimensions">160 x 600</div>
            </div>
          </div>
        </div>
      </FlexBox>
    );

  return (
    <FlexBox flexDirection="column" gap="0.75rem">
      <div className="creative-item item-728-90">
        <div className="creative-container">
          <img src={selectedLens.creatives['728-90']} alt="728-90" />
        </div>
        <div className="creative-dimensions">728 x 90</div>
      </div>
      <div className="creatives-container">
        <div className="grid-vertical">
          <div className="creative-item item-300-600">
            <div className="creative-container">
              <img src={selectedLens.creatives['300-600']} alt="300-600" />
            </div>
            <div className="creative-dimensions">300 x 600</div>
          </div>
        </div>
        <div className="grid-column">
          <div className="creative-item item-320-100">
            <div className="creative-container">
              <img src={selectedLens.creatives['320-100']} alt="320-100" />
            </div>
            <div className="creative-dimensions">320 x 100</div>
          </div>
          <div className="creative-item item-300-250">
            <div className="creative-container">
              <img src={selectedLens.creatives['300-250']} alt="300-250" />
            </div>
            <div className="creative-dimensions">300 x 250</div>
          </div>
          <div className="creative-item item-300-250">
            <div className="creative-container">
              <img src={selectedLens.creatives['300-250_1']} alt="300-250-1" />
            </div>
            <div className="creative-dimensions">300 x 250</div>
          </div>
        </div>
        <div className="grid-vertical">
          <div className="creative-item item-160-600">
            <div className="creative-container">
              <img src={selectedLens.creatives['160-600']} alt="160-600" />
            </div>
            <div className="creative-dimensions">160 x 600</div>
          </div>
        </div>
      </div>
    </FlexBox>
  );
}
