import React from 'react';
import type { Dispatch, SetStateAction } from 'react';

import { Text } from '@eltoro-ui/components';
import { ImageWithLink } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/AttachedCreatives/_components/ImageWithLink';

import type { CreativeUrls } from 'Pages/CampaignCreation/components/CampaignCreatives';

import type { CampaignType, CreativeType } from 'types';

import './AttachedCreatives.scss';

type AttachedCreativesProps = {
  creatives: CreativeType[];
  campaign: CampaignType;
  setGeneratedBanners: Dispatch<SetStateAction<CreativeType[]>>;
  setCreativeUUIDs: Dispatch<SetStateAction<string[]>>;
  creativeUUIDs: string[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  setCampaign: (value: CampaignType) => void;
  isSubmit: boolean;
  setnextClicked: (e: boolean) => void;
  setUrls?: Dispatch<SetStateAction<CreativeUrls>>;
  urls?: CreativeUrls;
};

export const AttachedCreatives = ({
  creatives,
  campaign,
  setCampaign,
  isSubmit,
  setFiles,
  setGeneratedBanners,
  creativeUUIDs,
  setCreativeUUIDs,
  setnextClicked,
  setUrls,
  urls,
}: AttachedCreativesProps) => {
  return (
    <div className="AttachedCreatives">
      <Text tag="div" on="white" size="xxl" UNSAFE_className="your_creative_heading">
        Your Creatives
      </Text>
      {Array.from(new Map(creatives?.map(item => [item.content_type, item])).keys())?.map(type => (
        <ImageWithLink
          key={type}
          setFiles={setFiles}
          setCreativeUUIDs={setCreativeUUIDs}
          setGeneratedBanners={setGeneratedBanners}
          creatives={creatives}
          setCampaign={setCampaign}
          creativeUUIDs={creativeUUIDs}
          campaign={campaign}
          isSubmit={isSubmit}
          type={type}
          setnextClicked={setnextClicked}
          setUrls={setUrls}
          urls={urls}
        />
      ))}
    </div>
  );
};
