import React, { useEffect, useState } from 'react';

import { Button, Modal } from '@eltoro-ui/components';

import CreativesContent from 'Pages/CampaignCreation/components/CampaignCreatives/_components/ChooseFromCreativesLibraryModal/elements/CreativesContent';

import type { CreativeType, TCreative, TResPagination } from 'types';
import { CreativeTypeEnum, CreativeStatusEnum } from 'enums';

import { getAllCreatives } from 'Requests/Request_Methods/creativesLibraryMethods';

import './ChooseFromCreativesLibraryModal.scss';
import { usePermissionContext } from '../../../../../../contexts/PermissionContext';

type ChooseFromCreativeLibraryModalProps = {
  open: boolean;
  onCancel: () => void;
  type: CreativeTypeEnum | null;
  campaignCreatives: CreativeType[];
  onConfirm: (creative: TCreative[], typeModal: CreativeTypeEnum) => void;
};

const ChooseFromCreativesLibraryModal = ({
  type,
  open,
  onCancel,
  campaignCreatives,
  onConfirm,
}: ChooseFromCreativeLibraryModalProps) => {
  const { userIsActive } = usePermissionContext();
  const [creatives, setCreatives] = useState<TCreative[]>([]);
  const [selectedCreatives, setSelectedCreatives] = useState<TCreative[]>([]);

  const [creativesLoading, setCreativesLoading] = useState<boolean>(false);
  const [moreCreativesLoading, setMoreCreativesLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<TResPagination>({
    total_count: 0,
    total_pages: 0,
  });

  const getCreatives = async (page: number, type: CreativeTypeEnum) => {
    try {
      if (creatives.length) setMoreCreativesLoading(true);
      else setCreativesLoading(true);
      const body = {
        creative_type: [type],
        status: [CreativeStatusEnum.ACTIVE],
      };

      const res = await getAllCreatives(page, 15, body);
      if (res.data) {
        const { creatives: resCreatives, total_creatives, total_pages } = res.data;
        if (creatives.length) setCreatives(creatives => [...creatives, ...resCreatives]);
        else setCreatives(resCreatives);
        setPagination({ total_count: total_creatives, total_pages });
      }
    } catch (e) {
      console.warn(e);
    } finally {
      if (creatives.length) setMoreCreativesLoading(false);
      else setCreativesLoading(false);
    }
  };

  const onSelectCreative = (creative: TCreative) => () => {
    setSelectedCreatives(selectedCreatives => {
      if (selectedCreatives.find(({ creative_uuid }) => creative.creative_uuid === creative_uuid))
        return selectedCreatives.filter(
          ({ creative_uuid }) => creative.creative_uuid !== creative_uuid
        );
      return [...selectedCreatives, creative];
    });
  };

  useEffect(() => {
    if (open && type && userIsActive) getCreatives(page, type);
  }, [page, open, type]);

  useEffect(() => {
    if (!open) {
      setCreatives([]);
      setSelectedCreatives([]);
      setPage(1);
    }
  }, [open]);

  const onConfirmSelection = () => {
    onConfirm(selectedCreatives, type as CreativeTypeEnum);
    onCancel();
  };

  const onLoadMore = () => setPage(page => page + 1);

  if (!open) return null;

  return (
    <Modal className="choose-from-creatives-library">
      <div className="choose-from-creatives-library-header">
        <span className="choose-from-creatives-library-header-title">
          Choose {type === 'video' ? 'Videos' : 'Banners'} from Creatives Library
        </span>
        {!!selectedCreatives.length && (
          <span className="choose-from-creatives-library-header-selected_count">
            <b>{selectedCreatives.length}</b> {type}
            {selectedCreatives.length > 1 && 's'} selected
          </span>
        )}
      </div>
      <div className="choose-from-creatives-library-content">
        <CreativesContent
          campaignCreatives={campaignCreatives}
          creatives={creatives}
          page={page}
          selectedCreatives={selectedCreatives}
          loading={creativesLoading}
          moreLoading={moreCreativesLoading}
          total_pages={pagination.total_pages}
          onSelectCreative={onSelectCreative}
          onLoadMore={onLoadMore}
        />
      </div>
      <div className="choose-from-creatives-library-footer">
        <Button onClick={onCancel} weight="bold" size="l">
          Cancel
        </Button>
        <Button
          kind="primary"
          weight="bold"
          size="l"
          disabled={creativesLoading || !selectedCreatives.length}
          onClick={onConfirmSelection}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ChooseFromCreativesLibraryModal;
