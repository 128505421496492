import React, { useEffect, useState } from 'react';
import type { FC, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'rc-tooltip';

import { Skeleton } from 'primereact/skeleton';
import toast from 'react-hot-toast';
// import { PieChart } from 'react-minimal-pie-chart';
//
// import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBooks } from '@fortawesome/pro-regular-svg-icons';

import { Avatar, Breadcrumbs, Text } from '@eltoro-ui/components';
import { NotificationsTooltip } from 'Pages';

import { setUnReadNotification, setUnReadNotificationCount } from 'Redux/actions';
import { getUnReadNotificationsCounts } from 'Requests';

import type { TRootState } from 'types';
import { usePermissionContext } from 'contexts/PermissionContext';
import { isCountOver99 } from 'Utils/helpers';

import './TopHeaderWithBreadCrumb.scss';

type TopHeaderWithBreadCrumbProps = {
  SetIsNotificationToggleChange: Dispatch<SetStateAction<boolean>>;
  isNotificationToggleChange: boolean;
};

export const TopHeaderWithBreadCrumb: FC<TopHeaderWithBreadCrumbProps> = ({
  SetIsNotificationToggleChange,
  isNotificationToggleChange,
}) => {
  const Location = useLocation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const { unreadCount } = useSelector((state: TRootState) => state.notificationsReducer);
  const { userIsActive } = usePermissionContext();
  const [breadCrumbPath, setBreadCrumbPath] = useState<any>([]);

  useEffect(() => {
    if (userIsActive) {
      getUnReadNotificationsCounts()
        .then(res => {
          const counts = res.data;
          if (counts?.some(nots => !!nots.unreads)) {
            dispatch(setUnReadNotification(true));
            dispatch(
              setUnReadNotificationCount(counts.reduce((count, not) => count + not.unreads, 0))
            );
          } else {
            dispatch(setUnReadNotification(false));
            dispatch(setUnReadNotificationCount(0));
          }
        })
        .catch(err => {
          toast.error(err.detail);
        });
    }
  }, [Location, userIsActive]);

  const getBreadCrumbPathFormat = (url: string) => {
    let output: any = url.split('/').filter(member => member); // .filter to get rid of the empty strings
    const regex = new RegExp(/[0-9]/);
    if (!output.length) {
      return [
        {
          path: '/',
          name: 'dashboard',
        },
      ];
    }

    output = output.map((member: any, index: any) => {
      const pathChanger = member === 'create-campaign';
      if (!regex.test(member)) {
        if (index) {
          const name =
            member === 'virtual-just-listed-just-sold' ? 'Virtual Just Listed/Just Sold' : member;
          return {
            path: Location.pathname,
            name,
          };
        }

        const campaignId = output[1];

        return {
          path: pathChanger ? `/create-campaign/${campaignId}/setup` : `/${member}`,
          name: member,
        };
      }
      return '';
    });
    output = output.filter((path: string) => path !== '');

    if (output.length > 2) {
      output[1].path = Location.pathname.substring(0, Location.pathname.lastIndexOf('/'));
    }

    return output;
  };

  useEffect(() => {
    setBreadCrumbPath(getBreadCrumbPathFormat(Location.pathname));
  }, [Location.pathname]);

  return (
    <div className="bread_crumb_container">
      <Breadcrumbs breadcrumbs={breadCrumbPath} />
      <div className="right_corner">
        <a
          href="https://beewohelp.zendesk.com/hc/en-us"
          aria-label="Go to Zendesk Help Center"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Tooltip
            placement="bottom"
            trigger="hover"
            showArrow
            overlay="Learning Center"
            getTooltipContainer={() => document.body}
          >
            <span style={{ margin: '0 15px 0 40px' }}>
              <FontAwesomeIcon
                icon={faBooks}
                className="sidebar_icon"
                style={{ color: '#2B1F0A' }}
              />
            </span>
          </Tooltip>
        </a>
        {/* {userData?.current_subscription?.status === 'in_trial' && ( */}
        {/*  <FlexBox alignItems="center" gap="8px"> */}
        {/*    <PieChart */}
        {/*      className="trial-pie" */}
        {/*      startAngle={-90} */}
        {/*      lengthAngle={360} */}
        {/*      totalValue={moment(userData?.current_subscription.trial_end).diff( */}
        {/*        moment(userData?.current_subscription.trial_start), */}
        {/*        'days' */}
        {/*      )} */}
        {/*      lineWidth={25} */}
        {/*      label={({ dataEntry }) => dataEntry.value} */}
        {/*      labelPosition={0} */}
        {/*      labelStyle={{ fontWeight: 700, fontSize: 35 }} */}
        {/*      background="rgba(252, 217, 23, 0.20)" */}
        {/*      data={[ */}
        {/*        { */}
        {/*          color: '#FFAB03', */}
        {/*          value: */}
        {/*            moment(`${userData?.current_subscription.trial_end}Z`).diff(moment(), 'days') +*/}
        {/*            1, */}
        {/*        }, */}
        {/*      ]} */}
        {/*    /> */}
        {/*    <span className="free-trial-text"> */}
        {/*      Your free trial ends in{' '} */}
        {/*      {moment(`${userData?.current_subscription.trial_end}Z`).diff(moment(), 'days') + 1}{' '} */}
        {/*      days */}
        {/*    </span> */}
        {/*  </FlexBox> */}
        {/* )} */}
        <button
          onClick={() => SetIsNotificationToggleChange(toggle => !toggle)}
          type="button"
          className="notification_button"
          disabled={!userIsActive}
        >
          {!!unreadCount && userIsActive && (
            <div className="notification-count">{isCountOver99(unreadCount)}</div>
          )}
          <FontAwesomeIcon
            icon={faBell}
            className="sidebar_icon"
            style={{
              color: '#2B1F0A',
            }}
          />
        </button>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Text on="grey-050" UNSAFE_className="bread_crumb_username">
            {userData?.first_name && userData?.last_name ? (
              `${userData.first_name} ${userData.last_name}`
            ) : (
              <Skeleton height="1.8rem" width="6rem" style={{ marginRight: '1rem' }} />
            )}
          </Text>

          <div>
            {userData?.first_name && userData?.last_name ? (
              <Avatar
                name={`${userData?.first_name} ${userData?.last_name}`}
                rounded
                size="m"
                src={userData?.profile_pic}
                UNSAFE_className="avatar"
              />
            ) : (
              <Skeleton shape="circle" size="3rem" />
            )}
          </div>
        </span>
      </div>
      {isNotificationToggleChange && (
        <NotificationsTooltip SetIsNotificationToggleChange={SetIsNotificationToggleChange} />
      )}
    </div>
  );
};
