import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { TabMenu } from 'primereact/tabmenu';
import type { MenuItem } from 'primereact/menuitem';

import { TCommonUser } from 'types';
import { usePermissionContext } from 'contexts/PermissionContext';
import { PagesType } from '@eltoro-ui/components/PageRoutes/PageRoutes';

export const PageNav = ({ pages, userData }: { pages: PagesType[]; userData?: TCommonUser }) => {
  const location = useLocation();
  const { userIsDeactivatedWithActiveOrderlines, userIsFailed } = usePermissionContext();
  const items: MenuItem[] = useMemo(
    () =>
      pages.map(page => ({
        label: page.title,
        url: page.path,
        template: item => {
          if (
            (userIsDeactivatedWithActiveOrderlines || userIsFailed) &&
            (page.path === '/profile/notification-settings' ||
              page.path === '/profile/integrations')
          ) {
            return (
              <div className={classNames('p-menuitem-link', 'disabled')}>
                <span className="p-menuitem-text">{item.label}</span>
              </div>
            );
          }
          return (
            <NavLink
              key={page.path}
              className="p-menuitem-link"
              to={page.path}
              exact={page.exactPath}
            >
              <span className="p-menuitem-text">{item.label}</span>
            </NavLink>
          );
        },
      })),
    [pages, userData]
  );

  const activeIndex = useMemo(() => {
    return items.findIndex(item => item.url === location.pathname);
  }, [location.pathname, items]);

  return (
    <TabMenu
      model={items}
      className="nav-menu"
      activeIndex={activeIndex}
      onTabChange={() => undefined}
    />
  );
};
