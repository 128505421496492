import React from 'react';
import { FlexBox } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs } from '@fortawesome/pro-light-svg-icons';
import './CampaignSetup.scss';

const HasSelectedGoalInfoBar = () => {
  return (
    <FlexBox alignItems="center" gap="12px" UNSAFE_className="info-box">
      <FontAwesomeIcon icon={faCrosshairs} style={{ fontSize: '32px' }} color="#FFAB03" />
      <FlexBox flexDirection="column" gap="6px">
        <p className="info-desc">You&apos;re on the Right Track: Your Goal Sets the Direction!</p>
        <p className="info-desc">
          To ensure your ads reach the right people, select audiences based on your chosen goal
        </p>
      </FlexBox>
    </FlexBox>
  );
};

export default HasSelectedGoalInfoBar;
