import React, { useState } from 'react';
import type { ReactNode } from 'react';

import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import { Button, FlexBox, GridBox, Text } from '@eltoro-ui/components';
import { Uploader } from 'Components/Uploader';

import {
  UploadAreaLayout,
  UploadTypes,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components/UploadAreaLayout';

import { CreativeTypeEnum } from 'enums';
import type { FileUploadType } from 'types';

import cross from 'assets/Images/cross.png';

import './UploadBannerModal.scss';
import BannerSizesModal from './components/BannerSizesModal';

type UploadBannerModalProps = {
  onClickOutside: () => void;
  onSubmit: (files: FileUploadType, type: CreativeTypeEnum) => void;
  typeModal: CreativeTypeEnum.BANNER;
  loading?: boolean;
  okText?: string | ReactNode;
};

export const UploadBannerModal = ({
  onClickOutside,
  onSubmit,
  typeModal,
  loading,
  okText = 'Confirm',
}: UploadBannerModalProps) => {
  const [sizesModalOpen, setSizesModalOpen] = useState(false);
  const [fileSubmited, setfileSubmited] = useState<any>();

  return (
    <div className="UploadBannerModal">
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <img
          src={cross}
          onClick={onClickOutside}
          alt="cross"
          className="PersonalInfoForm__cross"
          role="presentation"
        />
      </div>
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text tag="div" on="white" size="xxl" UNSAFE_className="UploadMailerModal__step-subheading">
          Upload Your Banner
        </Text>
      </GridBox>
      <div className="UploadBannerModal__hl" />
      <GridBox gap="1rem">
        <FlexBox alignItems="center" gap="0.5rem">
          <FontAwesomeIcon icon={faStar} color="#FFAB03" />
          <Text on="white" size="l">
            Recommended Parameters
          </Text>
        </FlexBox>
        <FlexBox flexDirection="column" gap="0.5rem">
          <FlexBox alignItems="center" gap="0.5rem" UNSAFE_className="creative-recommendations">
            <span className="label">Quantity:</span>
            <span className="text">
              <b>At least 3 different sizes</b>
            </span>
          </FlexBox>
          <FlexBox alignItems="center" gap="0.5rem" UNSAFE_className="creative-recommendations">
            <span className="label">Banner Size:</span>
            <span className="text">
              <b>300 x 250</b> (desktop, tablet, mobile), <b>320 x 100</b> (best for
              mobile/smartphones)
            </span>
            <Button
              size="m"
              kind="text"
              UNSAFE_style={{ fontWeight: 400 }}
              onClick={() => setSizesModalOpen(true)}
              UNSAFE_className="refresh-contacts"
            >
              See all sizes
            </Button>
          </FlexBox>
          <FlexBox alignItems="center" gap="0.5rem" UNSAFE_className="creative-recommendations">
            <span className="label">File Size per upload:</span>
            <span className="text">
              <b>up to 100kbs</b>
            </span>
          </FlexBox>
        </FlexBox>
      </GridBox>
      <div className="UploadBannerModal__upload_area_layout">
        <Uploader
          type="banner"
          fileSubmited={fileSubmited}
          onDropFile={(files: FileUploadType) => setfileSubmited(files)}
        >
          <UploadAreaLayout uploadType={UploadTypes.banner} />
        </Uploader>
      </div>
      <div className="UploadBannerModal__footer">
        <Button kind="default" size="l" weight="bold" onClick={onClickOutside}>
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          disabled={loading || !fileSubmited?.acceptedFiles?.length}
          loading={loading}
          onClick={async () => {
            if (fileSubmited?.acceptedFiles?.length > 0) {
              await onSubmit(fileSubmited, typeModal);
              await onClickOutside();
            } else {
              toast.error('Please add a banner creatives');
            }
          }}
        >
          {okText}
        </Button>
      </div>
      {sizesModalOpen && <BannerSizesModal onClickOutside={() => setSizesModalOpen(false)} />}
    </div>
  );
};
