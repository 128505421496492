import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import '../AdvancedOptions.scss';

export default function InfoNote() {
  return (
    <div className="info-note">
      <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '32px', color: '#007B94' }} />
      Please note: Campaigns submitted after 4:00 PM EST or outside business hours (Monday–Friday,
      9:00 AM–5:30 PM EST) will be reviewed on the next business day.
    </div>
  );
}
