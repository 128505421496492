import React, { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { CampaignGoalType } from 'Utils/constants';
import { GoalSectionTitles } from 'enums';
import AudienceEmptyStates from './AudienceEmptyStates';
import '../CampaignSetup.scss';

type AudiencesWithGoalsProps = {
  selectedGoals: CampaignGoalType[];
};

interface Section {
  title: string;
  content: ReactNode | string;
  empty: ReactNode;
}

const sections: Section[] = [
  {
    title: GoalSectionTitles.CONQUEST_LEADS,
    content: 'Conquest content',
    empty: <AudienceEmptyStates goal={GoalSectionTitles.CONQUEST_LEADS} />,
  },
  {
    title: GoalSectionTitles.RETENTION,
    content: 'Retention content',
    empty: <AudienceEmptyStates goal={GoalSectionTitles.RETENTION} />,
  },
  {
    title: GoalSectionTitles.PROMOTE_LISTING,
    content: 'PromoteListing content',
    empty: <AudienceEmptyStates goal={GoalSectionTitles.PROMOTE_LISTING} />,
  },
  {
    title: GoalSectionTitles.PROMOTION,
    content: 'Promotion content',
    empty: <AudienceEmptyStates goal={GoalSectionTitles.PROMOTION} />,
  },
];

export default function AudiencesWithGoals({ selectedGoals }: AudiencesWithGoalsProps) {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const initialOpenSections = sections.reduce((acc, section) => {
      if (!selectedGoals?.length) {
        acc[section.title] = section.title === 'Conquest Leads';
      } else {
        acc[section.title] = selectedGoals?.some(goal => goal.name === section.title);
      }
      return acc;
    }, {} as { [key: string]: boolean });
    setOpenSections(initialOpenSections);
  }, [selectedGoals]);

  const toggleSection = (title: string) => {
    setOpenSections(prevState => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  return (
    <div className="section-wrapper">
      {sections.map(({ title, content, empty }) => (
        <div key={title}>
          <div>
            <span className="section-title">{title}</span>
            <button
              onClick={() => toggleSection(title)}
              aria-label={`toggle ${title} section`}
              className="toggle-goal"
            >
              <FontAwesomeIcon icon={openSections[title] ? faCaretUp : faCaretDown} />
            </button>
          </div>
          {openSections[title] && <div>{empty}</div>}
        </div>
      ))}
    </div>
  );
}
