import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@eltoro-ui/components';
import cross from 'assets/Images/cross.png';
import { useDispatch } from 'react-redux';
import { userDetail } from 'Requests/Request_Methods/authUserMethods';
import { userRole } from 'Helpers';
import { setUser } from 'Redux/actions';
import { OnboardingModal } from '../OnboardingModal';
import './styles.scss';

interface Props {
  token: string | null;
  setUserToken: (token: string, email: string) => void;
  closeModal: () => void;
  reactivationFromLoginPage: boolean;
}

const ReturnModal = ({ token, setUserToken, closeModal, reactivationFromLoginPage }: Props) => {
  const dispatch = useDispatch();
  const [openReactivationFlow, setOpenReactivationFlow] = useState(false);

  const handleReactivationClick = () => {
    if (token) {
      userDetail(token).then(res => {
        if (token && res?.data?.email) {
          setUserToken(token, res?.data?.email);
          const userData = {
            user_role: userRole(res?.data?.is_super),

            ...res.data,
          };
          dispatch(setUser(userData));
          setOpenReactivationFlow(true);
        }
      });
    }
  };
  return (
    <>
      <Modal offClick={() => setOpenReactivationFlow(false)}>
        <div className="modal-container">
          <img src={cross} onClick={closeModal} alt="cross" className="cross" role="presentation" />
          <span className="emoji">🎉</span>
          <h4>Looks like you are ready to return!</h4>
          <p>
            Your account is currently deactivated. To continue using Beewo and regain access to your
            data, please click &quot;Reactivation&quot; below.
          </p>
          <p>
            If you need further assistance please contact our support team at{' '}
            <a href="mailto:legal@eltoro.com">legal@eltoro.com</a>.
          </p>
          <div className="footer">
            <Button
              size="l"
              kind="default"
              weight="bold"
              UNSAFE_className="cancel-button"
              onClick={() => closeModal()}
            >
              No, Thanks
            </Button>
            <Button
              UNSAFE_className="reactivate_button"
              kind="primary"
              weight="bold"
              size="l"
              onClick={handleReactivationClick}
            >
              Reactivate
            </Button>
          </div>
        </div>
      </Modal>
      {openReactivationFlow && localStorage.getItem('reactivation') && (
        <OnboardingModal
          setModalOpen={setOpenReactivationFlow}
          reactivationFromLoginPage={reactivationFromLoginPage}
        />
      )}
    </>
  );
};
export default ReturnModal;
