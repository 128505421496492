import React from 'react';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Button, Modal } from '@eltoro-ui/components';
import type { TCommonUser } from 'types';

import cross from 'assets/Images/cross.png';
import './DeactivateAccountModal.scss';

type DeactivateAccountModalProps = {
  user: TCommonUser;
  onDeactivateAccount: () => void;
  onCancel: () => void;
  loading?: boolean;
};

const DeactivateAccountModal = ({
  user: { current_subscription },
  onDeactivateAccount,
  onCancel,
  loading,
}: DeactivateAccountModalProps) => {
  return (
    <Modal className="cancel-subscription-modal" offClick={!loading ? onCancel : () => undefined}>
      <img
        src={cross}
        onClick={!loading ? onCancel : () => undefined}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <div className="cancel-subscription-modal-container">
        <div className="cancel-subscription-modal-content">
          <div className="warn-icon-container">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              color="rgba(248, 113, 113, 1)"
              style={{ fontSize: 32 }}
            />
          </div>
          <div className="cancel-subscription-modal-body">
            <span className="cancel-subscription-modal-body-title">Deactivate your account?</span>
            <div className="cancel-subscription-modal-body-description">
              {current_subscription?.status === 'in_trial' ? (
                <>
                  <span>Are you sure you want to deactivate your account?</span>
                  <span>You will lose access to your account until it is reactivated.</span>
                </>
              ) : (
                <>
                  <span>
                    Your account will be deactivated at the end of the current billing period on{' '}
                    <b>
                      {moment(
                        current_subscription?.current_term_end ??
                          current_subscription?.next_billing_at
                      ).format('MMMM D, YYYY')}
                    </b>
                    .
                  </span>
                  <span>
                    After deactivation, you will have 90 days to monitor the active campaigns or to
                    reactivate your account.
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="cancel-subscription-modal-footer">
          <Button weight="bold" size="l" onClick={!loading ? onCancel : () => undefined}>
            No, Thanks
          </Button>
          <Button
            kind="danger"
            weight="bold"
            size="l"
            loading={loading}
            onClick={onDeactivateAccount}
          >
            Deactivate
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateAccountModal;
