import React, { useState } from 'react';

import { Modal } from '@eltoro-ui/components';

import CampaignModalSider from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/components/CampaignModalSider';
import CampaignModalContent from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/components/CampaignModalContent';

import cross from 'assets/Images/cross.png';

import { lenses } from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/constants';
import type { LensType } from 'Components/CampaignExamplesWidget/components/CampaignExamplesModal/constants';

import './CampaignExamplesModal.scss';

interface CampaignExamplesModalProps {
  onCancel: VoidFunction;
  redirectToLens: boolean;
}

export default function CampaignExamplesModal({
  onCancel,
  redirectToLens,
}: CampaignExamplesModalProps) {
  const [selectedLens, setSelectedLens] = useState<LensType>(lenses[0]);

  const onSelectLens = (lens: LensType) => () => setSelectedLens(lens);

  return (
    <Modal className="campaign-example-modal">
      <img
        src={cross}
        onClick={onCancel}
        alt="cross"
        className="PersonalInfoForm__cross"
        role="presentation"
      />
      <CampaignModalSider selectedLens={selectedLens} onSelectLens={onSelectLens} />
      <CampaignModalContent selectedLens={selectedLens} redirectToLens={redirectToLens} />
    </Modal>
  );
}
