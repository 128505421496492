import React from 'react';

import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { DateRangePicker, Spacer, Text } from '@eltoro-ui/components';
import InfoNote from 'Components/SummaryCart/SummaryEditModal/components/AdvancedOptions/InfoNote';
import './DateRangeSelector.scss';

export const DateRangeSelector = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  minFlightDuration,
  message,
}: {
  startDate: Date;
  endDate: Date;
  onStartDateChange: (newStartDate: Date) => void;
  onEndDateChange: (newEndDate: Date) => void;
  minFlightDuration: number;
  message?: any;
}) => {
  const duration = moment(endDate).add({ days: 1 }).diff(moment(startDate), 'days');
  const sameMonth =
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear();

  const sameYear = startDate.getFullYear() === endDate.getFullYear();

  const options = {
    month: 'long',
    day: 'numeric',
  } as const;

  const optionsWithYear = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const;

  return (
    <div className="DateRangeSelector">
      <InfoNote />
      <div className="DateRangeSelector__header">
        <Text
          on="white"
          size="xxl"
          weight="bold"
          tag="div"
          UNSAFE_className="DateRangeSelector__pick_timeframe"
        >
          Pick Timeframe
        </Text>
        <Text
          on="white"
          kind="subdued"
          size="s"
          tag="div"
          UNSAFE_className="DateRangeSelector__pick_timeframe_subheading"
        >
          Your campaign and direct mail will go out within this timeframe
        </Text>
        <Spacer />
      </div>
      <div className="DateRangeSelector__body">
        <div className="DateRangeSelector__date-selection">
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            min={new Date()}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        </div>
        <div className="DateRangeSelector__date-display">
          <p className="DateRangeSelector__start_end_date">
            {sameMonth
              ? `${startDate.toLocaleString('default', {
                  month: 'long',
                })} ${startDate.getDate()}th - ${endDate.getDate()}th`
              : `${startDate.toLocaleDateString(
                  'default',
                  sameYear ? options : optionsWithYear
                )}th - ${endDate.toLocaleDateString(
                  'default',
                  sameYear ? options : optionsWithYear
                )}th`}
          </p>
          {typeof message === 'function' ? message(duration) : message}
          <div className="DateRangeSelector__info_container">
            <FontAwesomeIcon icon={faExclamationCircle} color="#757575" />
            <p>
              Based on your chosen criteria flight days cannot be less than {minFlightDuration}.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
